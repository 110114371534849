export function getItemKey(itemData, customize = false) {
  let uniqId = itemData.product_instance_id

  if (!customize && !itemData.typename.includes('Custom')) {
    uniqId = itemData.abstract_product_id
  }

  return uniqId + '-' + itemData.size.short_name
}

export function getProductFeedId(productSku, sizeName) {
  return `${productSku}-${sizeName}`
}

export function getArchetypeFromType(type) {
  switch (true) {
    case type.includes('-coat'):
      return 'Coat'
    case type.includes('-croptop'):
      return 'Croptop'
    case type.includes('-hoodie'):
      return 'Hoodie'
    case type.includes('-satinjack'):
      return 'Satinjack'
    default:
      return 'Jacket'
  }
}

export function isSafari() {
  return (
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === '[object SafariRemoteNotification]'
    })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification))
  )
}

export function base64ToFile(base64, filename, type) {
  let bStr = atob(base64),
    n = bStr.length,
    u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bStr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type })
}

function getRGB(hex) {
  if (typeof hex !== 'string' || hex?.length < 6) {
    return [255, 255, 255]
  }

  const hexWithoutHash = hex[0] === '#' ? hex.slice(1) : hex

  const r = parseInt(hexWithoutHash.substring(0, 2), 16)
  const g = parseInt(hexWithoutHash.substring(2, 4), 16)
  const b = parseInt(hexWithoutHash.substring(4, 6), 16)

  return [r, g, b]
}

// Get luminance value
function getLuminance(rgb) {
  return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2]
}

// Check if the colors are similar
export function isSimilarColor(hex1, hex2) {
  const rgb1 = getRGB(hex1)
  const lum1 = getLuminance(rgb1)

  const rgb2 = getRGB(hex2)
  const lum2 = getLuminance(rgb2)

  return Math.abs(lum1 - lum2) < 15
}

export function scrollToTop() {
  if ('scrollRestoration' in history) {
    history.scrollRestoration = 'manual'
  }

  window?.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })
}

export function scrollToEl(id) {
  document?.getElementById(id)?.scrollIntoView({ block: 'start', behavior: 'smooth' })
}

export const getFormattedPrice = (value, modifier) => {
  if (value === null || value === undefined) return '$0.00'

  let price = typeof value === 'string' ? parseFloat(value) : value

  if (modifier) price += parseFloat(modifier)

  return '$' + price.toFixed(2)
}
